/* src/services.css */

.services-container {
    background-color: lightblue;
    font-family: Arial, sans-serif;
    color: #333;
    padding: 20px 0; /* Vertical padding for content spacing */
    box-sizing: border-box;
    width: 100%;
    height: 100vh;
  }
  
  .services-content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  .services-container h1, .services-container p {
    text-align: center;
  }
  
  .services-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
  
  .service-item {
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 15px;
    text-align: center;
    box-sizing: border-box;
    width: calc(100% - 40px);
    max-width: 600px;
  }
  
  .service-item img {
    width: 100%;
    height: auto;
    border-radius: 8px 8px 0 0;
  }
  
  .service-item h2 {
    margin: 10px 0;
    font-size: 1.5em;
  }
  
  .service-item p {
    font-size: 1em;
    line-height: 1.5;
  }
  
  /* Desktop layout */
  @media (min-width: 769px) {
    .service-item {
        width: calc(50% - 40px); /* Two items per row with gap */
      }
    
      .service-item:nth-child(5) {
        width: calc(100% - 40px); /* Span both columns for the last item */
      }
    }

 /* Desktop layout */
  @media (max-width: 768px) {
    .services-container {
      margin-top: 1260px;
      margin-bottom: 120px;
      height: 2000px;
    }
  }
  