/* Client.css */

.client-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 20px;
    width: 100%;
    height: 100%;
    padding: 20px;
    box-sizing: border-box;
  }
  
  .client-box {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    font-size: 18px;
    font-weight: bold;
  }
  
  .client-box.top-left {
    background-color: #f0e68c; /* Light Khaki */
  }
  
  .client-box.top-right {
    background-color: #add8e6; /* Light Blue */
  }
  
  .client-box.bottom-left {
    background-color: #90ee90; /* Light Green */
  }
  
  .client-box.bottom-right {
    background-color: #ffcccb; /* Light Pink */
  }
  
  .box-link {
    color: #007bff;
    text-decoration: none;
  }
  
  .box-link:hover {
    text-decoration: underline;
  }
  
  /* Responsive layout for smaller screens */
  @media (max-width: 768px) {
    .client-container {
      grid-template-columns: 1fr;
      grid-template-rows: repeat(4, 1fr);
      gap: 10px;
      padding: 10px;
      margin-bottom: 120px;
    }
  }
  