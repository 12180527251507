/* form.css */

.form-container {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 600px;
  margin: 20px auto;
  margin-top: -30px;
  box-sizing: border-box;
}

h1 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #333333;
  text-align: center;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: 600;
  color: #555555;
}

.input-field, .select-field__control {
  width: 100%;
  padding: 10px;
  margin: 5px 0;
  box-sizing: border-box;
  border: 1px solid #cccccc;
  border-radius: 4px;
  font-size: 16px;
}

.input-field:focus, .select-field__control--is-focused {
  border-color: #007bff;
  outline: none;
}

.submit-button {
  width: 100%;
  padding: 10px 15px;
  font-size: 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submit-button:hover {
  background-color: #0056b3;
}

.contact-number-group {
  display: flex;
  flex-direction: column;
}

.contact-number-wrapper {
  display: flex;
  align-items: center;
}

.prefix-select {
  width: 200px; /* Increased width */
  margin-right: 10px;
}

.contact-number-input {
  flex-grow: 1;
  max-width: 150px; /* Reduced width */
}

.contact-options {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

.contact-option img {
  width: 40px;
  height: 40px;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .form-container {
    margin-top: 20px;
  }
  .contact-options {
    flex-direction: column; /* Stack vertically */
    align-items: center;
  }

  .contact-option {
    margin-bottom: 10px; /* Space between icons */
  }

  .contact-option:last-child {
    margin-bottom: 240px; /* Space between icons */
  }
}
