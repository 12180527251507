/* src/AboutUs.css */

.about-us-container {
    max-width: 800px;
    margin: 40px auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: left;
    line-height: 1.6;
  }
  
  .about-us-container h1 {
    font-size: 2em;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .about-us-container h2 {
    font-size: 1.5em;
    margin-top: 30px;
    margin-bottom: 10px;
  }
  
  .about-us-container p {
    margin-bottom: 20px;
    color: #333;
  }
  
  /* Mobile Styles */
  @media (max-width: 768px) {
    .about-us-container {
      margin: 20px 10px; /* Adjust margin for mobile */
      padding: 15px; /* Adjust padding for mobile */
      margin-bottom: 240px;
      margin-top: 600px;
    }
  
    .about-us-container h1 {
      font-size: 1.5em; /* Adjust font size for mobile */
    }
  
    .about-us-container h2 {
      font-size: 1.2em; /* Adjust font size for mobile */
    }
  
    .about-us-container p {
      font-size: 0.9em; /* Adjust font size for mobile */
    }
  }
  