/* General Styles */

html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden; /* Prevent horizontal overflow */
}

.App {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.navbar {
  display: flex;
  align-items: center;
  padding: 0 10px;
  background: #333;
  color: white;
  height: 60px; /* Fixed height of the navbar */
  position: fixed; /* Keep navbar at the top */
  top: 0;
  width: 100%;
  z-index: 1000; /* Ensure it stays above other content */
  justify-content: space-between;
  box-sizing: border-box;
}

.logo {
  display: flex;
  align-items: center;
}

.logo img {
  height: 50px;
  width: 50px;
}

.hamburger-menu {
  display: none; /* Hidden by default, shown on mobile */
  flex-direction: column;
  cursor: pointer;
}

.hamburger-menu div {
  width: 25px;
  height: 3px;
  background-color: white; /* Color of the hamburger lines */
  margin: 4px 0;
}

/* Menu Styles */
.menu {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
}

.menu-item {
  margin: 0 15px;
  cursor: pointer;
  color: white;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: relative;
  box-sizing: border-box;
}

.menu-item.op4 {
  padding: 15px 0; /* Increased padding for more height */
  position: relative;
  z-index: 1;
}

.dropdown-menu {
  position: relative;
}

.dropdown-content {
  position: absolute;
  top: 100%;
  left: 0;
  background: #333;
  color: white;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  display: none;
  flex-direction: column;
  width: 200px;
  z-index: 1000;
}

.menu-item:hover .dropdown-content,
.dropdown-content.show {
  display: flex;
}

.dropdown-item {
  padding: 8px 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  background-color: #333; /* Match the dropdown background */
}

.dropdown-item img {
  margin-right: 8px;
}

.dropdown-item:hover {
  background: #444; /* Change hover color for consistency */
}

.client-link {
  display: flex;
  align-items: center;
  height: 100%;
  box-sizing: border-box;
  text-decoration: none;
  color: white;
  background-color: green;
  padding: 0 15px;
  position: absolute;
  top: 0;
  right: 60px; /* Adjust this value for precise placement */
}

.language-dropdown {
  display: flex;
  align-items: center;
  height: 100%;
  box-sizing: border-box;
  text-decoration: none;
  color: white;
  background-color: lightgrey;
  padding: 0 15px;
  position: absolute; /* Position absolute for flexible positioning */
  top: 0;
  right: 0; /* Position it at the right edge of the container */
}

.language-dropdown .dropdown-title {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.language-dropdown .dropdown-title img {
  margin-right: 5px;
}

.language-dropdown .dropdown-content {
  position: absolute;
  top: calc(100%); /* Slight gap below the button */
  left: 0; /* Aligns the left side of the dropdown with the button */
  transform: translateX(-50%); /* Move the dropdown to the left of its parent */
  background: #333;
  color: white;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  display: none; /* Hidden by default */
  flex-direction: column;
  width: 130px;  /* Set width for consistency */
  z-index: 1000;
}

.language-dropdown .dropdown-content.show {
  display: flex;
}

.language-dropdown .dropdown-item {
  padding: 8px 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  background-color: #333;
}

.language-dropdown .dropdown-item:hover {
  background: #444; /* Consistent hover effect */
}

.content {
  margin-top: 60px;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start; /* Align items to start of container */
  background-color: #f0f0f0;
  overflow-y: auto; /* Allow vertical scrolling */
  width: 100%;
  box-sizing: border-box; /* Ensure padding is included in width */
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background: #333;
  color: white;
  flex-shrink: 0;
}

.contact-button {
  background-color: blue;
  color: white;
  padding: 10px 20px;
  text-align: center;
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
}

.social {
  display: flex;
  justify-content: center;
}

.icon {
  margin: 0 5px;
}

.icon img {
  height: 30px;
}

.company {
  text-align: center;
}

/* Mobile Styles */
@media (max-width: 768px) {
  /* Prevent page scrolling */
  html, body {
    overflow: hidden;
  }

  .menu {
    display: none;
  }

  .hamburger-menu {
    display: flex;
    position: absolute;
    top: 10px;
    left: 70px;
    background-color: transparent; /* Remove background color */
  }

  .navbar {
    padding: 0 10px;
    justify-content: flex-start;
  }

  .client-link {
    right: 60px;
  }

  .language-dropdown {
    right: 0;
    margin-right: 0;
  }

  .menu.show {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    background: #333;
    z-index: 999;
  }

  .menu-item {
    justify-content: flex-start;
    padding: 20px 15px;
    border-bottom: 1px solid #444;
    background-color: #333;
    width: 100%;
  }

  .menu-item.op4 {
    padding: 20px 15px;
    position: relative;
    z-index: 1;
  }

  .dropdown-content {
    position: static;
    box-shadow: none;
    background: #333;
    color: white;
    width: 100%;
  }

  .dropdown-item {
    color: white;
    padding: 20px 15px;
    background-color: #333;
  }

  .dropdown-item:hover {
    background-color: #444;
  }

  .content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background-color: #f0f0f0;
    width: 100%;
    box-sizing: border-box;
  }

  .footer {
    position: fixed; /* Fix footer to the bottom of the viewport */
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column; /* Stack content vertically */
    align-items: center;
    background: #333;
    padding: 20px 10px; /* Sufficient padding for content */
    z-index: 1000;
    box-sizing: border-box; /* Include padding in height calculation */
  }

  .social {
    display: flex;
    justify-content: center;
    gap: 10px;
    padding-top: 10px;
  }

  .social img {
    width: 30px;
    height: auto;
  }

  .contact-button {
    width: 100%; /* Full width button */
    padding: 10px;
    background-color: blue;
    color: white;
    text-align: center;
    border-radius: 5px;
    text-decoration: none;
    font-weight: bold;
  }

  .company {
    display: none; /* Hide company info */
  }
}
