/* Team.css */

.team-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px; /* Space between the cards */
    flex-wrap: wrap;
    padding: 20px;
    box-sizing: border-box;
    margin-bottom: 120px;
}
  
.team-card {
    width: 500px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.team-image {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    margin-bottom: 10px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.team-info {
    flex: 1;
    padding: 10px;
}

.team-info h3 {
    font-size: 18px;
    margin-bottom: 5px;
    color: #333;
}

.team-info h4 {
    font-size: 16px;
    margin-bottom: 10px;
    color: #555;
}

.team-info p {
    font-size: 14px;
    color: #666;
}

.team-socials {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.team-socials a {
    margin: 0 5px;
}

.team-socials img {
    width: 24px;
    height: 24px;
}

/* Mobile Styles */
@media (max-width: 768px) {
    .team-container {
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        height: auto; /* Adjust height for vertical stacking */
        gap: 20px; /* Adjust spacing for smaller screens */
        padding: 20px;
        margin-top: 650px;
    }
  
    .team-card {
        width: 90vw; /* Increase width on mobile */
        max-width: 500px; /* Maintain maximum width */
    }
  
    .team-image {
        width: 150px; /* Adjust image size on mobile */
        height: 150px;
    }
}
