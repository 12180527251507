/* src/Overlay.css */

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .overlay-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    position: relative;
    max-width: 700px;
    width: 90%;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
  }
  
  h2 {
    margin-bottom: 20px;
  }
  
  .contact-number {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .contact-options {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
  }
  
  .contact-option {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    color: black;
  }
  
  .contact-option img {
    width: 170px;
    height: 50px;
    margin-bottom: 10px;
  }
  