.landing-container {
  position: relative;
  width: 100%;
  height: 87vh; /* Full viewport height */
  margin: 0;
  padding: 0;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.video-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw; /* Full viewport width */
  height: 100vh; /* Full viewport height */
  object-fit: cover; /* Ensure the video covers the entire container */
  z-index: -1; /* Make sure the video stays behind the content */
}

.content {
  position: relative;
  z-index: 1; /* Ensure the content stays above the video */
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center content vertically */
  align-items: center; /* Center content horizontally */
  width: 100%;
  height: 100%;
  text-align: center;
}

h1 {
  font-size: 48px;
  color: #ffffff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
  margin: 0;
}

/* Media Queries */
@media (max-width: 768px) {
  .landing-container {
    height: calc(100vh - 60px); /* Account for the navbar height */
  }

  .content {
    padding-top: 0; /* Remove padding on mobile */
  }

  h1 {
    font-size: 32px;
  }
}

@media (max-width: 480px) {
  h1 {
    font-size: 28px;
  }
}
